// src/pages/EditBlogEntry.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function EditBlogEntry() {
  const [adminPassword, setAdminPassword] = useState('');
  const [verified, setVerified] = useState(false);
  const [posts, setPosts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState('');
  const [selectedPostContent, setSelectedPostContent] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch posts once the admin is verified.
  const fetchPosts = () => {
    fetch('/api/posts')
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to load posts');
        }
        return res.json();
      })
      .then((data) => {
        // Transform each post into an object with id, title, and fullContent.
        const parsedPosts = data.map((post) => parseMarkdownFromPost(post));
        setPosts(parsedPosts);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  // Verify the password via the backend.
  const handleVerifyPassword = async (e) => {
    e.preventDefault();
    setError('');
    if (adminPassword.trim() === '') {
      setError('Please enter the admin password.');
      return;
    }
    try {
      const res = await fetch('/api/verify', {
        method: 'GET',
        headers: {
          'x-admin-password': adminPassword,
        },
      });
      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.error || 'Invalid password');
      }
      // If verification is successful, show the editing UI.
      setVerified(true);
      fetchPosts();
    } catch (err) {
      setError(err.message);
    }
  };

  // When a post is selected, load its full markdown content for editing.
  useEffect(() => {
    if (selectedPostId) {
      const selected = posts.find((p) => p.id === selectedPostId);
      if (selected) {
        setSelectedPostContent(selected.fullContent);
      }
    } else {
      setSelectedPostContent('');
    }
  }, [selectedPostId, posts]);

  // Send a PUT request to update the selected post.
  const handleSaveChanges = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    if (!selectedPostId) {
      setError('No post selected.');
      return;
    }
    fetch(`/api/posts/${selectedPostId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-admin-password': adminPassword,
      },
      body: JSON.stringify({ content: selectedPostContent }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.error || 'Error updating blog post');
        }
        setMessage('Blog post updated successfully!');
        setTimeout(() => {
          navigate('/');
        }, 1500);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  // Send a DELETE request to remove the selected post.
  const handleDelete = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    if (!selectedPostId) {
      setError('No post selected for deletion.');
      return;
    }
    // Confirm deletion.
    const confirmDelete = window.confirm('Are you sure you want to delete this post?');
    if (!confirmDelete) return;
    try {
      const res = await fetch(`/api/posts/${selectedPostId}`, {
        method: 'DELETE',
        headers: {
          'x-admin-password': adminPassword,
        },
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || 'Error deleting blog post');
      }
      setMessage('Blog post deleted successfully!');
      // Refresh the posts list and clear selection.
      fetchPosts();
      setSelectedPostId('');
      setSelectedPostContent('');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="edit-blog-entry">
      <h2>Edit Blog Entry</h2>
      {!verified ? (
        <form onSubmit={handleVerifyPassword}>
          <div>
            <label htmlFor="adminPassword">Enter Admin Password:</label>
            <input
              type="password"
              id="adminPassword"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
              required
            />
          </div>
          <button className="button" id="verifyButton" type="submit">Verify</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      ) : (
        <>
          <div>
            <label htmlFor="postSelect">Select Blog Post to Edit:</label>
            <select
              id="postSelect"
              value={selectedPostId}
              onChange={(e) => setSelectedPostId(e.target.value)}
            >
              <option value="">-- Select a post --</option>
              {posts.map((post) => (
                <option key={post.id} value={post.id}>
                  {post.title}
                </option>
              ))}
            </select>
          </div>
          {selectedPostId && (
            <>
              <form onSubmit={handleSaveChanges}>
                <div>
                  <label htmlFor="content">Edit Content (Markdown):</label>
                  <textarea
                    id="content"
                    value={selectedPostContent}
                    onChange={(e) => setSelectedPostContent(e.target.value)}
                    rows="20"
                    className="edit-textarea"
                    required
                  />
                </div>
                <button className="button" id="submitButton" type="submit">Save Changes</button>
              </form>
              <button onClick={handleDelete} className="button" id="deleteButton">
                Delete Post
              </button>
            </>
          )}
          {message && <p className="message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
        </>
      )}
    </div>
  );
}

export default EditBlogEntry;

/**
 * Helper function to extract the title from markdown content.
 * This function looks for a line starting with "# " and returns the rest.
 */
function extractTitleFromContent(content) {
  const lines = content.split('\n');
  for (let line of lines) {
    if (line.startsWith('# ')) {
      return line.replace(/^#\s*/, '').trim();
    }
  }
  return 'Untitled Post';
}

/**
 * Parses a raw post (with _id and content) and returns an object with:
 *  - id: the post's _id,
 *  - title: extracted from the markdown content,
 *  - fullContent: the original markdown text.
 */
function parseMarkdownFromPost(post) {
  return {
    id: post._id,
    title: extractTitleFromContent(post.content),
    fullContent: post.content,
  };
}
