function Footer() {
  return (
    <footer
      style={{
        backgroundColor: '#0E0E0E',
        color: '#fff',
        boxSizing: 'border-box',
        position: 'absolute',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '75px',
      }}
    >
      <div style={{ maxWidth: '80%', textAlign: 'center' }}>
        <h2 style={{ fontFamily: 'Montserrat, sans-serif', fontSize: '1rem', marginBottom: '1rem' }}>About Trailventure</h2>
        <p style={{ fontSize: '0.8rem', lineHeight: '1.5', }}>
          Trailventure is a platform built by me, Simon, where I share my enthusiasm for exploring nature by foot.
          {/*Here, you’ll discover trusted gear recommendations, insights into events, and best training practices,*/}
          {/*helping you navigate every trail with confidence.*/}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
