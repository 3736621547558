import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function PostDetail() {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Construct path to the markdown file in the public/posts_equipment directory
    // Since 'id' might be something like 'SP_post1' or 'PP_post2', we just append '.md'.
    const markdownFile = `/posts_equipment/${id}.md`;

    fetch(markdownFile)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Markdown file not found at ${markdownFile}`);
        }
        return response.text();
      })
      .then(text => {
        setContent(text);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div style={{ padding: '2rem', textAlign: 'center' }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ padding: '2rem', textAlign: 'center', color: 'red' }}>{error}</div>;
  }

  return (
    <div style={{
      maxWidth: '800px',
      margin: '0 auto',
      marginTop: 75,
      padding: '2rem', 
      backgroundColor: '#fff',
      color: '#111',
    }}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
}

export default PostDetail;
