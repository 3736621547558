import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="navbar">
      {/* Left section: brand link */}
      <div className="navbar-left">
        <Link to="/" className="navbar-link">
          TRAILVENTURE
        </Link>
      </div>

      {/* Right section: menu */}
      <div 
        className="navbar-right"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button className="navbar-menu-button">
          Menu
        </button>
        <div className={`navbar-dropdown ${menuOpen ? 'open' : ''}`}>
          {/*<Link to="/equipment" className="navbar-dropdown-link">Equipment</Link>*/}
          <Link to="/blog" className="navbar-dropdown-link">Blog</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
