// src/pages/NewBlogEntry.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NewBlogEntry() {
  const [content, setContent] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const response = await fetch('/api/posts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-admin-password': adminPassword, // Required for authentication
        },
        body: JSON.stringify({ content }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Error creating blog post');
      }
      setMessage('Blog post created successfully!');
      // Optionally, redirect to your blog overview page after a delay:
      setTimeout(() => {
        history.push('/'); // Adjust the route as needed
      }, 1500);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="post-input">
      <h2>Create New Blog Entry</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="content">
            Blog Content (Markdown)
          </label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows="20"
            required
          />
        </div>
        <div>
          <label htmlFor="adminPassword">
            Admin Password
          </label>
          <input
            id="adminPassword"
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {message && <p style={{ color: 'green', marginTop: '1rem' }}>{message}</p>}
      {error && <p style={{ color: 'red', marginTop: '1rem' }}>{error}</p>}
    </div>
  );
}

export default NewBlogEntry;
