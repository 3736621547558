import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// --- Main Blog Component ---
function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetch all posts from the MongoDB API.
    fetch('/api/posts')
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to load blog posts');
        }
        return res.json();
      })
      .then(data => {
        // data is an array of posts, each with at least { _id, content }.
        const parsedPosts = data.map(post => parseMarkdownFromPost(post));

        // Sort posts by effective date descending (most recent first)
        parsedPosts.sort((a, b) => {
          const effectiveDateA = extractEffectiveDate(a.date);
          const effectiveDateB = extractEffectiveDate(b.date);
          return effectiveDateB - effectiveDateA;
        });

        setPosts(parsedPosts);
      })
      .catch(err => {
        console.error('Error fetching posts:', err);
      });
  }, []);

  return (
    <div className="blog">
      <h2>Blog</h2>
      <div className="blog-grid">
        {posts.map(post => (
          <Link
            key={post.id}
            to={`/blog/${post.id}`}
            className="blog-card"
            style={{
              backgroundImage: post.image ? `url(${post.image})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="blog-card-title">{post.title}</div>
            {post.date && <div className="blog-card-date">{post.date}</div>}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Blog;

/**
 * parseMarkdownFromPost:
 * Receives a post object from the database (with fields _id and content)
 * and returns an object containing id, title, date, image.
 */
function parseMarkdownFromPost(post) {
  // Extract header data and the remaining markdown content.
  const { headerData, strippedContent } = extractHeaderData(post.content);
  const { finalTitle } = extractTitle(strippedContent);

  return {
    id: post._id, // Assuming _id is stringifiable.
    title: finalTitle || 'Untitled Post',
    date: headerData.date || '',
    image: headerData.image || ''
  };
}

/**
 * extractHeaderData:
 * Looks for a header block demarcated by '---' lines.
 * Parses lines of the format "key: value" and returns:
 *    headerData: { date, image, ... },
 *    strippedContent: the markdown without the header block.
 */
function extractHeaderData(fullText) {
  const lines = fullText.split('\n');
  let headerData = {};
  let insideHeaderBlock = false;
  const contentLines = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    if (line.trim() === '---') {
      insideHeaderBlock = !insideHeaderBlock;
      continue;
    }

    if (insideHeaderBlock) {
      const match = line.match(/^([^:]+):\s*(.*)$/);
      if (match) {
        const key = match[1].trim();
        const value = match[2].trim();
        headerData[key] = value;
      }
    } else {
      contentLines.push(line);
    }
  }

  return {
    headerData,
    strippedContent: contentLines.join('\n')
  };
}

/**
 * extractTitle:
 * Finds the first markdown header starting with "# " and uses that as the title.
 * Returns an object containing:
 *    finalTitle: the title string,
 *    finalBody: the remaining markdown content.
 */
function extractTitle(text) {
  const allLines = text.split('\n');
  let finalTitle = '';
  const bodyLines = [];

  for (let i = 0; i < allLines.length; i++) {
    const line = allLines[i];
    if (line.startsWith('# ')) {
      finalTitle = line.replace(/^#\s*/, '').trim();
    } else {
      bodyLines.push(line);
    }
  }

  return {
    finalTitle,
    finalBody: bodyLines.join('\n')
  };
}

/**
 * extractEffectiveDate:
 * Given a date string in one of the supported formats:
 *   - "DD.MM.YYYY"
 *   - "DD.-DD.MM.YYYY"
 *   - "DD.MM.-DD.MM.YYYY"
 *   - "DD.MM.YYYY-DD.MM.YYYY"
 * Returns a Date object corresponding to the effective (end) date used for sorting.
 */
function extractEffectiveDate(dateStr) {
  if (!dateStr) return new Date(0);
  dateStr = dateStr.trim();

  // Check for a simple single date: "DD.MM.YYYY"
  let matchSingle = dateStr.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
  if (matchSingle) {
    const day = parseInt(matchSingle[1], 10);
    const month = parseInt(matchSingle[2], 10);
    const year = parseInt(matchSingle[3], 10);
    return new Date(year, month - 1, day);
  }

  // If a dash is present, assume it's a range and use the latter date.
  if (dateStr.includes('-')) {
    // Pattern 1: "DD.MM.YYYY-DD.MM.YYYY"
    let matchFullRange = dateStr.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})-(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
    if (matchFullRange) {
      const day = parseInt(matchFullRange[4], 10);
      const month = parseInt(matchFullRange[5], 10);
      const year = parseInt(matchFullRange[6], 10);
      return new Date(year, month - 1, day);
    }

    // Pattern 2: "DD.-DD.MM.YYYY"
    let matchDayRange = dateStr.match(/^(\d{1,2})\.-(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
    if (matchDayRange) {
      const day = parseInt(matchDayRange[2], 10);
      const month = parseInt(matchDayRange[3], 10);
      const year = parseInt(matchDayRange[4], 10);
      return new Date(year, month - 1, day);
    }

    // Pattern 3: "DD.MM.-DD.MM.YYYY"
    let matchDayMonthRange = dateStr.match(/^(\d{1,2})\.(\d{1,2})\.-(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
    if (matchDayMonthRange) {
      const day = parseInt(matchDayMonthRange[3], 10);
      const month = parseInt(matchDayMonthRange[4], 10);
      const year = parseInt(matchDayMonthRange[5], 10);
      return new Date(year, month - 1, day);
    }

    // Fallback: if a dash exists but no pattern matched, try splitting on '-' and parsing the second part.
    const parts = dateStr.split('-');
    if (parts.length > 1) {
      const secondPart = parts[1].trim();
      let matchFallback = secondPart.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
      if (matchFallback) {
        const day = parseInt(matchFallback[1], 10);
        const month = parseInt(matchFallback[2], 10);
        const year = parseInt(matchFallback[3], 10);
        return new Date(year, month - 1, day);
      }
    }
  }

  // If no patterns match, return a fallback date (epoch)
  return new Date(0);
}
