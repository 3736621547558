//import React, { useState, useEffect } from 'react';
//import background1 from '../assets/hero-triangle-animation/1.png';
//import background2 from '../assets/hero-triangle-animation/2.png';
import background3 from '../assets/hero-triangle-animation/3.png';

function Home() {
  // Slideshow images
  //const backgrounds = [background1, background2, background3];
  //const [currentIndex, setCurrentIndex] = useState(0);

  // Switch images every 2 seconds
  //useEffect(() => {
    //const interval = setInterval(() => {
      //setCurrentIndex(prevIndex => (prevIndex + 1) % backgrounds.length);
    //}, 2000);

    //return () => clearInterval(interval);
  //});

  return (
    <>
      {/* The "hero-section" uses the background from the currentIndex. 
          The rest of its styles come from App.css. */}
      <div
        className="hero-section"
        style={{
          //backgroundImage: `url(${backgrounds[currentIndex]})`,
          backgroundImage: `url(${background3})`,
          backgroundSize: '35vh auto',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="hero-text-container">
          <h1 className="hero-title">
            {/*START
            <br />
            YOUR
            <br />
            TRAILRUNNING
            <br />
            ADVENTURE
            <br />
            HERE*/}
            WELCOME
            <br />
            TO
            <br />
            SIMON'S
            <br />
            TRAIL
            <br />
            ADVENTURES
          </h1>
        </div>
      </div>
    </>
  );
}

export default Home;
