import React, { useEffect, useState } from 'react';
import PostCard from '../components/PostCard';

// Known filenames of markdown posts in public/posts_equipment:
const postFilenames = [
  'SP_shoes.md',
  'SP_clothes.md',
  'SP_poles.md',
  'SP_backpacks.md',
  'PP_salomon_active_skin.md',
  'PP_asics_gel_trabuco_12.md',
  'PP_3.md',
  'PP_4.md',
  'PP_5.md',
  'PP_6.md',
  'PP_7.md',
  'PP_8.md',
  'PP_9.md',
  'PP_10.md',
  'PP_11.md',
  'PP_12.md',
];

function Equipment() {
  const [posts, setPosts] = useState([]);
  const [filterType, setFilterType] = useState('SP'); // Default to SP or PP as needed

  useEffect(() => {
    // Fetch and parse all markdown files
    Promise.all(
      postFilenames.map(filename =>
        fetch(`/posts_equipment/${filename}`)
          .then(res => {
            if (!res.ok) throw new Error(`Failed to load ${filename}`);
            return res.text();
          })
          .then(text => {
            // Parse markdown content
            // Assume the first line is a title, e.g. "# Finding the Perfect Trail Shoe"
            // We'll extract the first line as title (removing '#') and the next few lines as excerpt.
            const lines = text.split('\n');
            let title = 'Untitled Post';
            if (lines.length > 0) {
              title = lines[0].replace(/^#\s*/, '').trim();
            }

            // Determine prefix from filename (SP or PP)
            const prefix = filename.split('_')[0]; // "SP" or "PP"
            
            // Construct a link for a detail page (like /equipment/:id)
            // Let's say we take the filename without extension for the link:
            const link = `/equipment/${filename.replace('.md', '')}`;

            // Placeholder image logic (you may customize images based on type)
            const image = prefix === 'SP'
              ? 'https://images.unsplash.com/photo-1511389571644-a2550b195566?q=80&w=2139&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              : 'https://images.unsplash.com/photo-1621650784637-eb439621916c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

            return {
              id: filename,
              title,
              image,
              link,
              prefix
            };
          })
          .catch(err => {
            console.error(err);
            return null;
          })
      )
    ).then(loadedPosts => {
      // Filter out any null (failed) loads
      const validPosts = loadedPosts.filter(p => p !== null);
      setPosts(validPosts);
    });
  }, []);

  const filteredPosts = posts.filter(post => post.prefix === filterType);

  return (
    <div style={{ 
      maxWidth: '1200px', 
      margin: '0 auto', 
      padding: '2rem',
      marginTop: 75,
    }}>
      <h2 style={{ 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: '2rem', 
        marginBottom: '2rem',
        textAlign: 'center' 
      }}>
        Equipment Insights & Reviews
      </h2>

      {/* Filter dropdown */}
      <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
        <label htmlFor="filter-select" style={{ marginRight: '0.5rem', fontFamily: 'Inter, sans-serif' }}>
          Show Posts:
        </label>
        <select
          id="filter-select"
          value={filterType}
          onChange={e => setFilterType(e.target.value)}
          style={{ fontSize: '1rem', padding: '0.5rem', fontFamily: 'Inter, sans-serif' }}
        >
          <option value="SP">Starter Posts (SP)</option>
          <option value="PP">Product Posts (PP)</option>
        </select>
      </div>

      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', 
        gap: '1rem',
      }}>
        {filteredPosts.map(post => (
          <PostCard key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
}

export default Equipment;
