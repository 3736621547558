import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Equipment from './pages/Equipment';
import Blog from './pages/Blog';
import PostDetail from './pages/PostDetail';
import BlogPostDetail from './pages/BlogPostDetail';
import NewBlogPost from './pages/NewBlogPost';
import EditBlogPost from './pages/EditBlogPost';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <div style={{ fontFamily: 'Inter, sans-serif', color: '#333' }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/equipment" element={<Equipment />} />
        <Route path="/equipment/:id" element={<PostDetail />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPostDetail />} />
        <Route path="/blog/new" element={<NewBlogPost />} />
        <Route path="/blog/edit" element={<EditBlogPost />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
