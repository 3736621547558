import React from 'react';
import { Link } from 'react-router-dom';

function PostCard({ post }) {
  return (
    <div style={{
      borderRadius: '0px', 
      overflow: 'hidden', 
      background: 'rgba(211, 74, 52, 0.05)',
    }}>
      <img 
        src={post.image} 
        alt={post.title} 
        style={{ width: '100%', height: 'auto', display: 'block' }} 
      />
      <div style={{ padding: '1rem' }}>
        <Link 
          to={post.link}
          style={{ 
            display: 'inline-block', 
            marginTop: '1rem', 
            textDecoration: 'none', 
            color: '#111', 
            fontWeight: '600' 
          }}
        >
          {post.title}
        </Link>
      </div>
    </div>
  );
}

export default PostCard;
