import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

// --- Custom Components for Markdown Rendering ---

// Custom image renderer to add lazy loading and styling.
function ImageRenderer({ src, alt }) {
  return (
    <img 
      src={src} 
      alt={alt} 
      loading="lazy" 
      style={{ maxWidth: '75%', display: 'block', margin: '1rem auto 2rem auto' }} 
    />
  );
}

// Custom table renderer to wrap tables in a responsive container.
function TableRenderer({ children }) {
  return (
    <div style={{ overflowX: 'auto', margin: '1em 0' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        {children}
      </table>
    </div>
  );
}

// Custom code block renderer for syntax highlighting.
const CodeBlock = ({ node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter style={coy} language={match[1]} PreTag="div" {...props}>
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

// --- Main BlogPostDetail Component ---
function BlogPostDetail() {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the single blog post from the MongoDB API.
    fetch(`/api/posts/${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Blog post not found');
        }
        return response.json();
      })
      .then(post => {
        // post.content contains the markdown text.
        const { headerData, strippedContent } = extractHeaderData(post.content);
        const { finalTitle, finalBody } = extractTitle(strippedContent);

        setDate(headerData.date || '');
        setTitle(finalTitle);
        setContent(finalBody);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div style={{ padding: '2rem', textAlign: 'center' }}>Loading...</div>;
  }

  if (error) {
    return (
      <div style={{ padding: '2rem', textAlign: 'center', color: 'red' }}>
        {error}
      </div>
    );
  }

  return (
    <div 
      className="post"
      style={{ 
        maxWidth: '80vh', 
        margin: '0 auto', 
        padding: '2rem',
        paddingTop: '5rem',
        minHeight: '75vh'
      }}
    >
      {title && <h1>{title}</h1>}
      {date && (
        <p style={{ fontStyle: 'italic', marginTop: 0, marginBottom: '1rem' }}>
          {date}
        </p>
      )}
      <div className="post-content markdown">
        <ReactMarkdown 
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            img: ImageRenderer,
            table: TableRenderer,
            code: CodeBlock,
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default BlogPostDetail;

/**
 * extractHeaderData:
 * Looks for a header block demarcated by '---' lines containing key: value pairs.
 * Returns an object with:
 *   - headerData: the metadata (e.g., { date, image }),
 *   - strippedContent: the markdown text with the header removed.
 */
function extractHeaderData(fullText) {
  const lines = fullText.split('\n');
  let headerData = {};
  let insideHeaderBlock = false;
  const contentLines = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    if (line.trim() === '---') {
      insideHeaderBlock = !insideHeaderBlock;
      continue;
    }

    if (insideHeaderBlock) {
      const match = line.match(/^([^:]+):\s*(.*)$/);
      if (match) {
        const key = match[1].trim();
        const value = match[2].trim();
        headerData[key] = value;
      }
    } else {
      contentLines.push(line);
    }
  }

  return {
    headerData,
    strippedContent: contentLines.join('\n')
  };
}

/**
 * extractTitle:
 * Searches for the first line starting with "# " as the title.
 * Returns an object containing:
 *    - finalTitle: the extracted title,
 *    - finalBody: the remaining markdown content.
 */
function extractTitle(text) {
  const allLines = text.split('\n');
  let finalTitle = '';
  const bodyLines = [];

  for (let i = 0; i < allLines.length; i++) {
    const line = allLines[i];
    if (line.startsWith('# ')) {
      finalTitle = line.replace(/^#\s*/, '').trim();
    } else {
      bodyLines.push(line);
    }
  }

  return {
    finalTitle,
    finalBody: bodyLines.join('\n')
  };
}
